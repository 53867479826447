<template>
  <div class="card-content table-card-content">
    <b-table striped hoverable :data="pspdList" :mobile-cards="false">
      <b-table-column field="no" label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
      <b-table-column field="npm" label="NPM" v-slot="props">{{ props.row.npm }}</b-table-column>
      <b-table-column field="pspd" label="Mahasiswa" v-slot="props">{{ props.row.pspd }}</b-table-column>
      <b-table-column field="final" label="Final" v-slot="props">{{ formatNilai(props.row.nilai) }}</b-table-column>
      <b-table-column field="mutu" label="Mutu" v-slot="props">{{ props.row.predikat }}</b-table-column>
      <b-table-column field="lulus" label="Pass/Fail" v-slot="props">
        <hasil-mark :hasil="props.row.lulus"></hasil-mark>
      </b-table-column>

      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <p>Tidak ada data mahasiswa terdaftar.</p>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="pagination.showPagination"
      :total="pagination.total"
      :per-page="pagination.limit"
      :current.sync="pagination.page"
      order="is-centered"
      icon-pack="fas"
      @change="pageChange"
    ></b-pagination>
  </div>
</template>

<script>
import PSPDList from "../models/pspdList.js";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";

export default {
  name: "PenilaianTable",
  props: {
    stase: null,
  },
  mixins: [onlineCheckMixin, paginationMixin],
  data() {
    this.listMdl = new PSPDList(this.stase.id);
    return this.listMdl.getObservables();
  },
  components: {
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  methods: {
    fetchData() {
      this.listMdl.load(this.onLoaded);
    },
    formatNilai(nilai) {
      if (nilai == null) return "";
      const nilaiStr = nilai.toString();
      return nilaiStr.length > 2
        ? nilai.toString().replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")
        : nilai;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
