import { render, staticRenderFns } from "./PenilaianTable.vue?vue&type=template&id=1a5a6552&"
import script from "./PenilaianTable.vue?vue&type=script&lang=js&"
export * from "./PenilaianTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports